<template>
  <div v-if="!submitted">
    <Card class="mb-1">
      <template v-slot:header>Новый образовательный день</template>
      <div class="row">
        <div class="col-12">

          <div class="input-label mb-3">
            <span>Курс</span>
          </div>

          <Select :data="courses" @change="selectedCourse = $event">
            <template v-slot:placeholder="{selectedItem}">
                <div v-if="selectedItem !== null && selectedItem !== undefined" class="d-flex flex-row align-items-center">
                    <div>{{selectedItem.name}}</div>
                </div>
                <span v-else>Выберите курс</span>
            </template>
            <template v-slot:default="{item}">
                <div class="d-flex flex-row align-items-center text-">
                    <div class="w-75">{{item.name}}</div>
                </div>
            </template>
          </Select>

          <Input
            v-model="day.name"
            placeholder="Тема №1"
            label="Название дня"
            class="mt-3"
            required
          ></Input>

          <div>
            <div class="input-label">
              <label>Приветственное изображение дня</label>
            </div>
            <Dropzone
              v-slot:default="{ files, removeFileByIndex, active, browse }"
              ref="dropzone"
              class="mb-3"
              @change="day_images = $event"
            ></Dropzone>
          </div>

          <div v-if="day_images && day_images.length > 0">
            <div class="mt-4">Добавленные файлы:</div>
            <div v-for="file in day_images">
              <div class="input-label">
                <label>{{ file.name }}</label>
              </div>
              <img style="max-width: 100px;" :src="file.src"/>
            </div>
          </div>
          
        </div>
      </div>
      <div class="input-group mt-3">
        <Button @click="saveDay" type="outline-light-bg-success">Создать</Button>
      </div>
    </Card>
  </div>
  <div v-else>
    <h4>День создан!</h4>
    <button class="btn btn-success" @click="newDay">Добавить еще один</button>
  </div>
</template>

<script>
import DayService from "../../services/DayService";
import CourseService from "../../services/CourseService";
import swal from "sweetalert";

var defaultData = {
  id: "",
  name: "",
  course_id: "",
  day_images: []
};

export default {
  name: "NewDayForms",

  data() {
    return {
      day: {
        id: defaultData.id,
        course_id: defaultData.course_id,
        name: defaultData.name,
      },
      courses: [],
      day_images: defaultData.day_images,
      selectedItem:null,
      submitted: false,
      selectedCourse: null,
    };
  },

  methods: {
    saveDay() {
      if (!this.selectedCourse) {
        swal("Ошибка!", "Поле «Курс» не заполнено.", "warning");
        return;
      }

      if (!this.day.name) {
        swal("Ошибка!", "Поле «Название дня» не заполнено.", "warning");
        return;
      }

      if (!this.day_images || this.day_images.length === 0) {
        swal("Ошибка!", "Поле «Приветственное изображение дня» не заполнено.", "warning");
        return;
      }

      let data = new FormData()
      data.append('course_id', this.selectedCourse.id);
      data.append('name', this.day.name);
      data.append('image', this.day_images[0]);

      DayService.create(data)
        .then(response => {
          this.day.id = response.data._id;
          this.submitted = true;
        })
        .catch(e => {
          swal("Ошибка!", "Что то пошло не так", "error");
          console.log(e.toJSON());
        });
    },

    async newDay() {
      this.submitted = false;
      this.day.id = defaultData.id;
      this.day.name = defaultData.name;
      this.day_images = defaultData.day_images;
      await this.$nextTick();
    },

    getBase64 (file, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(file);
    }
  },

  async mounted() {
    let data = await CourseService.getAll();
    data.forEach((newValue, index) => {
      this.$set(this.courses, index, {id: newValue._id, name: newValue.name});
    });
  },

  watch: {
    day_images: function(images){
      let vm = this;
      images.forEach(image => {
        if (image.src) {
          return;
        }

        this.getBase64(image, function(base64Data){
            image.src = base64Data;
            vm.$forceUpdate();
        });
      });
    }
  }
};
</script>

<style scoped>
</style>