var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.submitted)?_c('div',[_c('Card',{staticClass:"mb-1",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Новый образовательный день")]},proxy:true}],null,false,3955851434)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"input-label mb-3"},[_c('span',[_vm._v("Курс")])]),_c('Select',{attrs:{"data":_vm.courses},on:{"change":function($event){_vm.selectedCourse = $event}},scopedSlots:_vm._u([{key:"placeholder",fn:function(ref){
var selectedItem = ref.selectedItem;
return [(selectedItem !== null && selectedItem !== undefined)?_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_vm._v(_vm._s(selectedItem.name))])]):_c('span',[_vm._v("Выберите курс")])]}},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-items-center text-"},[_c('div',{staticClass:"w-75"},[_vm._v(_vm._s(item.name))])])]}}],null,false,905339955)}),_c('Input',{staticClass:"mt-3",attrs:{"placeholder":"Тема №1","label":"Название дня","required":""},model:{value:(_vm.day.name),callback:function ($$v) {_vm.$set(_vm.day, "name", $$v)},expression:"day.name"}}),_c('div',[_c('div',{staticClass:"input-label"},[_c('label',[_vm._v("Приветственное изображение дня")])]),_c('Dropzone',{ref:"dropzone",staticClass:"mb-3",on:{"change":function($event){_vm.day_images = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var files = ref.files;
var removeFileByIndex = ref.removeFileByIndex;
var active = ref.active;
var browse = ref.browse;
return undefined}}],null,false,863649126)})],1),(_vm.day_images && _vm.day_images.length > 0)?_c('div',[_c('div',{staticClass:"mt-4"},[_vm._v("Добавленные файлы:")]),_vm._l((_vm.day_images),function(file){return _c('div',[_c('div',{staticClass:"input-label"},[_c('label',[_vm._v(_vm._s(file.name))])]),_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":file.src}})])})],2):_vm._e()],1)]),_c('div',{staticClass:"input-group mt-3"},[_c('Button',{attrs:{"type":"outline-light-bg-success"},on:{"click":_vm.saveDay}},[_vm._v("Создать")])],1)])],1):_c('div',[_c('h4',[_vm._v("День создан!")]),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.newDay}},[_vm._v("Добавить еще один")])])}
var staticRenderFns = []

export { render, staticRenderFns }